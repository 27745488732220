import _ from 'lodash';
import { HeadlessCategory } from '@/services/headlessCapture.constants';

let captureMetadata: { category?: HeadlessCategory } = {};

/**
 * Indicates what category of job this headless browser page is mapped to.
 *
 * @returns the category of the job for which this page is rendering. If this page is not rendering in a headless
 *   browser, then this function returns undefined
 */
export function headlessRenderCategory() {
  return captureMetadata.category;
}

/**
 * Test if {@param category} is a HeadlessCategory
 */
export function isHeadlessCategory(category: unknown): category is HeadlessCategory {
  return _.isString(category) && Object.values<string>(HeadlessCategory).includes(category);
}

/**
 * Determines if the app is rendered by in the headless browser for screen capture
 *
 * @return {Boolean} whether or not the application is rendered in the headless browser
 *
 * To emulate headless capture mode, add the following code to the function (which is helpful for debugging since it
 *  emulates what Puppeteer does with the headless browser):
 *  if (!_.isFunction(window.seeqHeadlessCapture)) {
 *    window.seeqHeadlessCapture = _.noop;
 *    window.seeqHeadlessCategory = _.constant(Promise.resolve('Screenshot'));
 *    window.seeqHeadlessLoading = _.noop;
 *    window.seeqHeadlessError = _.noop;
 *    window.seeqHeadlessWarning = _.noop;
 *    window.seeqHeadlessCancellation = _.noop;
 *    window.seeqJobFormat = _.constant(Promise.resolve('PDF'));
 *  }
 */
export function headlessRenderMode(): boolean {
  return _.isFunction(window.seeqHeadlessCapture);
}

/**
 * Fetches the category of headless capture being requested so that it can be accessed synchronously in the page load
 */
export function fetchHeadlessCaptureMetadata() {
  if (!headlessRenderMode()) {
    return Promise.resolve();
  }

  return Promise.resolve(window.seeqHeadlessCategory()).then((category) => {
    if (!isHeadlessCategory(category)) {
      throw new Error(`Error: '${category}' category is unexpected; the frontend enum likely needs to be updated`);
    }
    captureMetadata = {
      category,
    };
  });
}
