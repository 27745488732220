// @ts-strict-ignore
import React, { useEffect, useRef } from 'react';
import _ from 'lodash';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFlux } from '@/core/hooks/useFlux.hook';
import { IconSelect } from '@/core/IconSelect.molecule';
import { CheckboxSelect, CheckBoxSelectOption } from '@/core/CheckboxSelect.molecule';
import { FormInput } from '@/core/FormInput.atom';
import { KEY_CODES } from '@/main/app.constants';
import { getTypeTranslation } from '@/utilities/utilities';
import { Icon } from '@/core/Icon.atom';
import { TextButton } from '@/core/TextButton.atom';
import { SearchPanes, SORT_BY_OPTIONS } from '@/search/search.constants';
import { doTrack } from '@/track/track.service';
import {
  clear,
  getStoreForPane,
  searchItems,
  setAdvancedMode,
  setDescriptionFilter,
  setNameFilter,
  setSortBy,
  toggleDatasource,
  toggleType,
} from '@/search/search.actions';

interface SearchFiltersProps {
  pane: SearchPanes;
  searchTypes: string[];
  scopeIds: string[];
  excludeGloballyScoped?: boolean;
}

export const SearchFilters: React.FunctionComponent<SearchFiltersProps> = (props) => {
  const { pane, searchTypes, scopeIds, excludeGloballyScoped = false } = props;
  const { t } = useTranslation();

  const nameFilterRef = useRef(null);
  const store = getStoreForPane(pane);

  const focusNameInput = () => nameFilterRef.current.focus();
  useEffect(focusNameInput, []);

  const {
    nameFilter,
    descriptionFilter,
    searching,
    currentAsset,
    datasourceFilter,
    typeFilter,
    sortBy,
    isAdvancedMode,
    datasources,
  } = useFlux(store);

  const search = () => {
    doTrack('Search', 'Search started');

    return searchItems(pane, searchTypes, scopeIds, excludeGloballyScoped).then(() => {
      const searchSummary = {
        advancedMode: isAdvancedMode,
        nameSpecified: !!nameFilter,
        descriptionSpecified: !!descriptionFilter,
        assetSpecified: !!currentAsset,
        datasourceFilterCount: _.size(datasourceFilter),
        sortBy,
      };
      _.forEach(
        searchTypes,
        (searchType) => (searchSummary[`typeFilter-${searchType}`] = _.includes(typeFilter, searchType)),
      );

      doTrack('Search', 'Search Complete', JSON.stringify(searchSummary));
    });
  };

  const typeFilterOptions = _.map(searchTypes, (type) => ({
    text: getTypeTranslation(type),
    value: type,
  }));

  return (
    <div
      className={classNames('card card-default', {
        borderGray: pane === 'modal',
      })}>
      <div className="card-body" data-testid="searchPanel">
        <FormInput
          className="mb10 height-34"
          id="searchInput"
          name="nameFilter"
          data-testid="nameFilter"
          placeholder={t('SEARCH_DATA.NAME_CONTAINS')}
          value={nameFilter}
          ref={nameFilterRef}
          autoComplete="none"
          onChange={(e) => setNameFilter(pane, e.target.value)}
          onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
        />

        {isAdvancedMode && (
          <span className="advancedFormWrapper">
            <FormInput
              className="mb10 height-34"
              name="descriptionFilter"
              data-testid="descriptionFilter"
              placeholder={t('SEARCH_DATA.DESCRIPTION_CONTAINS')}
              value={descriptionFilter}
              onKeyUp={(e) => e.keyCode === KEY_CODES.ENTER && search()}
              onChange={(e) => setDescriptionFilter(pane, e.target.value)}
            />

            <CheckboxSelect
              testId="typeFilter"
              value={typeFilter}
              selectOptions={typeFilterOptions}
              isMultipleSelect={true}
              insideModal={pane === 'modal'}
              wrapperClasses="mb10"
              label="SEARCH_DATA.ALL_TYPES"
              placeholder="SEARCH_DATA.ALL_TYPES"
              onChange={(type) => toggleType(pane, type.value)}
            />

            <CheckboxSelect
              testId="datasourceFilter"
              value={datasourceFilter}
              selectOptions={_.chain(datasources)
                .map<any, CheckBoxSelectOption>((datasource) => ({
                  text: datasource.name,
                  value: datasource,
                }))
                .sortBy((datasource) => datasource.text.toLowerCase())
                .value()}
              isMultipleSelect={true}
              insideModal={pane === 'modal'}
              wrapperClasses="mb10"
              label="SEARCH_DATA.ALL_DATASOURCES"
              placeholder="SEARCH_DATA.ALL_DATASOURCES"
              onChange={(datasource) => toggleDatasource(pane, datasource.value)}
            />

            <IconSelect
              name="sortByFilter"
              value={sortBy}
              insideModal={pane === 'modal'}
              wrapperClasses="mb10"
              selectOptions={_.map(SORT_BY_OPTIONS, (option, key) => ({
                text: `SEARCH_DATA.SORT_BY_OPTIONS.${key}`,
                value: option,
              }))}
              onChange={(sort) => setSortBy(pane, _.toString(sort.value))}
            />
          </span>
        )}

        <div className="flexColumnContainer flexSpaceBetween">
          <TextButton
            label="SEARCH_DATA.RESET"
            onClick={() => {
              clear(pane, searchTypes, false, scopeIds, excludeGloballyScoped);
              nameFilterRef.current.focus();
            }}
            testId="resetButton"
          />
          <div>
            <a
              className="force-link-color mr10 cursorPointer"
              data-testid="moreFiltersButton"
              onClick={() => {
                setAdvancedMode(pane, !isAdvancedMode);
                doTrack('Search', 'Search advanced mode');
              }}>
              {t('ADVANCED')}
              <Icon icon={isAdvancedMode ? 'fa-angle-up' : 'fa-angle-down'} extraClassNames="ml2" />
            </a>
            <TextButton
              id="spec-search-btn"
              label="SEARCH"
              variant="theme"
              icon={searching ? 'fa-spinner fa-spin fa-pulse' : 'fa-search'}
              disabled={searching}
              iconStyle="white"
              type="submit"
              onClick={search}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
